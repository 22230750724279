<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-switch
          v-model="form.active"
          label="Активность"
        ></v-switch>

        <v-select
          v-model="$v.form.role.$model"
          :error-messages="getErrors('form.role')"
          required
          :items="roles"
          label="Роль"
        ></v-select>

        <v-text-field 
            v-model="$v.form.login.$model"
            :error-messages="getErrors('form.login')"
            autocomplete="new-email"
            label="Логин" 
            name="login" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.new_password.$model"
            :error-messages="getErrors('form.new_password')"
            autocomplete="new-password"
            label="Пароль" 
            name="new_password" 
            type="password" 
            placeholder=" " 
            color="light-green"
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.confirm_password.$model"
            :error-messages="getErrors('form.confirm_password', {
                compare: 'Пароль не совпадает с подтверждением'
            })"
            label="Подтверждение пароля" 
            name="confirm_password" 
            type="password" 
            placeholder=" " 
            color="light-green"
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="ФИО" 
            name="name" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

export default {
    name: 'UserForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'user',
            form: {
                active: true,
                role: '',
                login: '',
                new_password: '',
                confirm_password: '',
                name: ''
            }
        };
    },
    computed: {
        ...mapState('user', ['roles'])
    },
    validations() {
        const PASSWORD_MIN_LENGTH = 8;
        return {
            form: {
                role: {
                    required
                },
                login: {
                    required,
                    email
                },
                new_password: {
                    required: function(value) {
                        return this.action === 'create' ? required(value) : true;
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                confirm_password: {
                    required: function(value) {
                        return this.form.new_password.length > 0 ? required(value) : true;
                    },
                    compare: function(value) {
                        return this.form.new_password.length > 0 ? this.form.new_password === value : true;  
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                name: {
                    required,
                    minLength: minLength(2)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">

</style>